<template>
  <div class="directory">
    <div style="height: 200px;width: 100%;"></div>
    <div class="titleRight">
      {{objAll.title}}
    </div>
    <div class="contant">
      <div>目录</div>
      <ul>
        <li v-for="(item,index) in nameList" :key="index" :style="{'margin-right': index ? '0' : ''}"><span>{{!index ? item.name : `${index}. ${item.name}`}}</span><span :style="{'border-bottom': !index ? '3px dotted' : '2px dotted'}"></span><span>{{item.page}}</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objAll: {
      type: Object,
      default: function () {
        return { title: ''}
      }
    },
    nameList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {},
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.directory {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 40px;
  box-sizing: border-box;
  margin-bottom: 100px;
  color: #000;
  padding-top: 400px;

  .titleRight {
    width: 100%;
    font-size: 42px;
    display: flex;
    justify-content: flex-end;
  }

  .contant {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    font-size: 30px;
    &>div{
      margin: 100px auto;
      font-weight: 600;
    }
    ul{
      display: flex;
      flex-direction: column;
      &>li{
        display: flex;
        line-height: 24px;
        margin: 20px;
        &>span:nth-child(2){
          flex: 1;
          border-bottom: 1px dotted;
        }
      }
      &>li:nth-child(1){
        font-size: 32px;
        line-height: 28px;
        margin: 0 0 10px 0
      }
    }

  }
}
</style>